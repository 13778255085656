export class PriceFilter {

    rangeInput = document.querySelectorAll(".range-input input");

    inputMin = document.querySelector(".input-min");
    inputMax = document.querySelector(".input-max");


    range = document.querySelector(".slider .progress");
    priceGap = 10;

    constructor() {
        this.events();
    }

    events() {
        this.rangeInput.forEach((input) => {
            input.addEventListener("input", (e) => {
                let minVal = parseInt(this.rangeInput[0].value),
                    maxVal = parseInt(this.rangeInput[1].value);

                if (maxVal - minVal < this.priceGap) {
                    if (e.target.className === "range-min") {
                        this.rangeInput[0].value = maxVal - this.priceGap;
                    } else {
                        this.rangeInput[1].value = minVal + this.priceGap;
                    }
                } else {
                    this.inputMin.innerHTML = minVal;
                    this.inputMax.innerHTML = maxVal;
                    this.range.style.left = ((minVal / this.rangeInput[0].max) * 100) - 10 + "%";
                    this.range.style.right = 103 - (maxVal / this.rangeInput[1].max) * 100 + "%";
                }
            });
        });
    }
}