import ProductListHover from "./ProductListHover";
import Configurator from "../configurator/configurator"

export default class AddToCart {
    constructor() {
        this.noticesWrapper = document.getElementById("page__notices-wrapper");
        this.noticeContainer = document.querySelector(".jsPageNoticeWrapper");

        this.events();
    }

    events() {
        document.querySelectorAll("body")[0].addEventListener("click", (e) => {
            if (e.target.classList.contains("js-add-to-cart")) {
                e.preventDefault();
                this.addToCartRequest(e.target);
            } else if (e.target.closest(".js-add-to-cart")) {
                e.preventDefault();
                this.addToCartRequest(e.target.closest(".js-add-to-cart"));
            }
        });
    }

    addToCartRequest(element) {
        let qty = 1;

        if (element.closest(".quantity-wrapper")) {
            qty = element
                .closest(".quantity-wrapper")
                .querySelector(".qty").value;
        }

        if (isNaN(qty) || qty === "") {
            qty = 1;
        }

        const id = element.dataset.productId;
        const isProductWithConfigurator = element.dataset.configurable;

        let addons = undefined;
        if(isProductWithConfigurator) {
            const configurator = new Configurator();
            if(!configurator.validate()) {
                return;
            }
            addons = configurator.prepareFormData().filter(item => item !== id);
        }

        if (!element.classList.contains("loading")) {
            element.classList.add("loading");

            setTimeout(() => element.classList.remove("loading"), 3600);
        }

        fetch(window.app_global.ajax_url + "?action=addProductToCart", {
            method: "POST", //* GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", //* default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                // "Content-Type": "application/json",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: new URLSearchParams({
                security: window.app_global.security,
                product: id,
                quantity: qty,
                isConfigurable: addons?.length > 0,
                addons
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    const cartCount = document.querySelector(
                        ".header__cart-count"
                    );

                    cartCount.innerHTML = data.data.cart_count;

                    if (
                        data.data.wc_notices != null &&
                        data.data.wc_notices !== ""
                    ) {
                        this.noticesWrapper.innerHTML = data.data.wc_notices;

                        if (this.noticeContainer) {
                            this.noticeContainer.classList.remove("is-hidden");

                            window.setTimeout(() => {
                                this.noticeContainer.classList.add("is-hidden");
                            }, 10000);
                        }
                    }
                }

                new ProductListHover();
            });
    }
}
