import Splide from "@splidejs/splide";

export default class ProductImageSlider {
    init() {
        this.thumbnailSlider = document.querySelector(".jsThumbnailSlider");
        this.mainSlider = document.querySelector(".jsMainSlider");

        if (this.mainSlider) {
            this.initializeMainSlider();
        }

        if (this.thumbnailSlider) {
            this.initializeThumbnailSlider();
        }

        if (this.thumbnailSlider && this.mainSlider) {
            this.initializeConfiguratorSlider();
        }
    }

    initializeThumbnailSlider() {
        this.thumbnails = new Splide(this.thumbnailSlider, {
            gap: 8,
            width: 138,
            height: 438,
            fixedHeight: 138,
            fixedWidth: 138,
            rewind: true,
            pagination: false,
            arrows: true,
            isNavigation: true,
            direction: "ttb"

        });
    }

    initializeMainSlider() {
        this.main = new Splide(this.mainSlider, {
            rewind: true,
            type: "fade",
            heightRatio: 1,
            pagination: false,
            autoWidth: true,
            focus: "center",
            arrows: true,
        });
    }

    initializeConfiguratorSlider() {
        this.main.sync(this.thumbnails);
        this.thumbnails.mount();
        this.main.mount();
    }

    refreshSlides() {
        this.main.refresh();
        this.thumbnails.refresh();
    }
}
