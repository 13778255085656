export default class SearchForm {
    constructor() {
        this.searchToggleBtns = document.querySelectorAll('.jsSearchToggle')
        this.searchForm = document.querySelector('.jsSearchForm')

        this.searchToggleBtns.forEach(btn => {
            btn.addEventListener('click', (event) => {

                event.preventDefault();
                this.searchForm.classList.toggle('active')
            })
        })

    }
}
