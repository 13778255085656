export default class Tabs {
    constructor() {
        this.tabsMenu = [...document.querySelectorAll(".jsTabsMenu")];
        this.tabsContent = document.querySelectorAll(".jsTabsTab");
        this.target = document.querySelector(".jsTarget");

        this.init();
    }

    init() {
        this.tabsMenu.forEach((tabMenu) => {
            Array.from(tabMenu.children).forEach((child) => {
                if (!this.target.style.width) {
                    const width =
                        child.children[0].getBoundingClientRect().width;
                    const height =
                        child.children[0].getBoundingClientRect().height;
                    const left = child.children[0].getBoundingClientRect().left;

                    const parentLeft =
                        child.parentElement.getBoundingClientRect().left;

                    this.target.style.width = `${width}px`;
                    this.target.style.height = `55px`;
                    this.target.style.left = `${left - parentLeft}px`;
                }

                child.addEventListener("click", (e) => {
                    e.preventDefault();
                    this.toggle(child.dataset.name);

                    const width =
                        child.children[0].getBoundingClientRect().width;
                    const height =
                        child.children[0].getBoundingClientRect().height;
                    const left = child.children[0].getBoundingClientRect().left;

                    const parentLeft =
                        child.parentElement.getBoundingClientRect().left;

                    this.target.style.width = `${width}px`;
                    this.target.style.height = `${height}px`;
                    this.target.style.left = `${left - parentLeft}px`;
                });
                if (child.className.includes("is-active")) {
                    this.toggle(child.dataset.name);
                }
            });
        });
    }

    toggle(targetName) {
        this.tabsContent.forEach((contentElement) => {
            if (window.innerWidth > 1024) {
                contentElement.style.display =
                    contentElement.id === targetName ? "block" : "none";
                document
                    .querySelector(`[data-name="${contentElement.id}"]`)
                    .classList[
                        contentElement.id === targetName ? "add" : "remove"
                    ]("is-active");
            }
        });
    }
}
