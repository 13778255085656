export default class ScrollHeader {
    constructor() {
        this.body = document.querySelector("body");
        this.header = document.querySelector(".header");
        
        this.lastScroll = 0;
        this.threshold = 20;

        this.registerListeners();
    }

    registerListeners() {

        window.addEventListener("scroll", () => {
            let newScroll = window.scrollY

            if (newScroll - this.lastScroll > this.threshold) {
                this.body.classList.add("sticky");
                this.header.classList.add("header--sticky");
            } else if (newScroll - this.lastScroll < -this.threshold) {
                this.body.classList.remove("sticky");
                this.header.classList.remove("header--sticky");
            }

            this.lastScroll = newScroll
        })
    }
}

