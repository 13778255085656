/* eslint-disable no-unused-vars */
/**
 * Import 3rd party packages
 */

/**
 * Import modules / classes
 */

import AOS from "aos";
import "aos/dist/aos.css";

import "fslightbox";
import MobileMenu from "./modules/MobileMenu";
import GetProductsByAttr from "./modules/GetProductsByAttr";
import ProductImageSlider from "./modules/ProductImageSlider";
import QuantityButtons from "./modules/QuantityButtons";
import ScrollHeader from "./modules/ScrollHeader";
import Accordion from "./modules/Accordion";
import Tabs from "./modules/Tabs";
import ExitIntentPopup from "./modules/ExitIntentPopup";

import CategoryTreeAccordion from "./modules/CategoryTreeAccordion";
import CategoryTreeItemHover from "./modules/CategoryTreeItemHover";
import SearchForm from "./modules/SearchForm";
import { PriceFilter } from "./modules/PriceFilter";
import UpsellSlider from "./modules/Upsellslider";
import AddToCart from "./modules/AddToCart";
import RelatedProductsSlider from "./modules/RelatedProductsSlider";
import ContactFormModal from "./modules/ContactFormModal";

new MobileMenu();
new SearchForm();
new ProductImageSlider().init();
new GetProductsByAttr();
new QuantityButtons();
new ScrollHeader();
new Accordion("jsAccordionHeader");
new Tabs();
new CategoryTreeAccordion("cat-item__icon");
new CategoryTreeItemHover();
// new ExitIntentPopup();
new PriceFilter();
new UpsellSlider();
new AddToCart();
new RelatedProductsSlider();
new ContactFormModal();

AOS.init({
  disable: "mobile",
  offset: 200,
  once: false,
  anchorPlacement: "top-bottom",
});

setTimeout(() => {
  AOS.refresh();
}, 200);

document.querySelectorAll("a img.size-full").forEach((item) => {
  if (!item.parentNode.dataset.nolightbox == "") {
    item.parentNode.dataset.fslightbox = "desc";
  }
});

refreshFsLightbox();

document.querySelectorAll('.gf_readonly textarea').forEach(item => {
  item.setAttribute('readonly', 'true')
})