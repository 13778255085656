/* eslint-disable prefer-const */
import $ from "jquery";

export default class QuantityButtons {
    constructor() {
        this.buttonInitialized = false;
        this.events();
    }

    events() {
        const self = this;
        this.setButtonsEvents();

        $(document).ajaxComplete(function (event, request, settings) {
            if (
                settings.url === "/?wc-ajax=get_refreshed_fragments" ||
                settings.url === "/?wc-ajax=update_shipping_method"
            ) {
                self.setButtonsEvents();
            }
        });

        document.querySelector('.jsArchiveContainer')?.addEventListener('DOMSubtreeModified', () => {
            this.buttonInitialized = false;
            self.setButtonsEvents();
        })
    }

    setButtonsEvents() {
        if (this.buttonInitialized) return;

        this.quantityContainer = [
            ...document.querySelectorAll(".quantity-wrapper .quantity"),
        ];
        this.updateCartBtn = document.querySelector(
            'button[name="update_cart"]'
        );

        this.cartPage = document.querySelector("body.woocommerce-cart");
        this.isCartPage = !!this.cartPage;

        if (!this.quantityContainer.length) return;

        this.quantityContainer.forEach((item) => {
            const minusBtn = item.querySelector(".quantity-minus");
            const plusBtn = item.querySelector(".quantity-plus");
            const quantity = item.querySelector('input[type="number"]');

            minusBtn.addEventListener("click", (e) => {
                this.subtractProduct(e, item, quantity);
                quantity.dispatchEvent(new Event('change'));
            });

            plusBtn.addEventListener("click", (e) => {
                this.addProduct(e, item, quantity);
                quantity.dispatchEvent(new Event('change'));
            });

            quantity.addEventListener("blur", (e) => {
                this.changeQuantity(e, item, quantity);
                quantity.dispatchEvent(new Event('change'));
            });
        });

        this.buttonInitialized = true;
    }

    addProduct(e, item, quantity) {
        let value = parseInt(quantity.value, 10);
        const maxVal = parseInt(quantity.max, 10);

        if (!maxVal || (maxVal && value < maxVal)) {
            quantity.value = value + 1;
        } else {
            quantity.value = maxVal;
        }

        if (this.isCartPage) this.changeQtyTimeout();
    }

    subtractProduct(e, item, quantity) {
        let value = parseInt(quantity.value, 10);
        const minVal = parseInt(quantity.min, 10);

        if ((!minVal && value > 1) || (minVal && minVal < value)) {
            quantity.value = value - 1;
        } else if (minVal && minVal >= value) {
            quantity.value = minVal;
        } else {
            quantity.value = value;
        }

        if (this.isCartPage) this.changeQtyTimeout();
    }

    changeQuantity(e, item, quantity) {
        let value = parseInt(quantity.value, 10);
        const minVal = parseInt(quantity.min, 10);
        const maxVal = parseInt(quantity.max, 10);

        if (!minVal && value < 1) {
            quantity.value = 1;
        } else if (minVal && value < minVal) {
            quantity.value = minVal;
        } else if (maxVal && value > maxVal) {
            quantity.value = maxVal;
        } else {
            quantity.value = value;
        }

        if (this.isCartPage) this.changeQtyTimeout();
    }

    changeQtyTimeout() {
        const self = this;

        if (self.timeout !== undefined) {
            clearTimeout(self.timeout);
        }

        self.timeout = setTimeout(() => {
            self.updateCart();
        }, 500);
    }

    updateCart() {
        this.updateCartBtn.setAttribute("aria-disabled", "false");
        this.updateCartBtn.removeAttribute("disabled");
        $('[name="update_cart"]').trigger("click");
        this.updateCartBtn.click();

        this.buttonInitialized = false;
    }
}
