export default class CategoryTreeItemHover {
    constructor() {
        this.items = [
            ...document.querySelectorAll(".cat-item"),
        ];
        this.timeout = undefined;

        this.events();
    }

    events() {
        this.items.forEach((e) => {
            e.addEventListener("mouseover", (element) => {
                element.stopPropagation();
                this.addClass(element);
            });

            e.addEventListener("mouseout", (element) => {
                this.removeClass(element);
                element.stopPropagation();

            });
        });
    }

    addClass(element) {
        element.target.closest('.cat-item').classList.add('hover')
    }

    removeClass(element) {
        element.target.closest('.cat-item').classList.remove('hover')
    }
}
