import Loader from "./Loader";

export default class GetProductsByAttr {
    constructor() {
        this.filter_el = document.getElementsByClassName("jsAttrFilter");
        this.deleteElementsBtn =
            document.getElementsByClassName("jsDeleteFilter");
        this.attrList = new Map();
        this.activeFilters = [];
        this.selectedSort = "date_desc";
        this.archiveContainer = document.querySelector(".jsArchiveContainer");
        this.categoryTitle = document.querySelector(".archive__title");
        this.archiveContainer = document.querySelector(".jsArchiveContainer");
        this.loadMoreElement = document.querySelector(".load-more");
        this.loadMoreIcon = document.querySelector(".load-more__icon");
        this.pageNumber = 1;

        this.priceMin = document.querySelector('.range-min');
        this.priceMax = document.querySelector('.range-max');
        this.orderBy = document.querySelector('.orderby');

        this.isActiveFilters = this.activeFilters.length > 0;
        this.events();
    }

    events() {
        document.querySelectorAll("body")[0].addEventListener("click", (e) => {
            if (e.target.classList.contains("jsAttrFilter")) {
                e.preventDefault();
                this.prepareSelectRequest(e.target);
            } else if (e.target.closest(".jsAttrFilter")) {
                e.preventDefault();
                this.prepareSelectRequest(e.target.closest(".jsAttrFilter"));
            } else if (e.target.classList.contains("jsDeleteFilter")) {
                e.preventDefault();
                this.prepareDeleteRequest(e.target);
            } else if (e.target.closest(".jsDeleteFilter")) {
                e.preventDefault();
                this.prepareDeleteRequest(e.target.closest(".jsDeleteFilter"));
            } else if (e.target.classList.contains("jsChangePage")) {
                if (!this.isActiveFilters) {
                    return;
                }
                e.preventDefault();
                this.pageNumber = e.target.dataset.pagenum;
                this.getProductsByAttr(Array.from(this.attrList));
            } else if (e.target.closest(".jsChangePage")) {
                if (!this.isActiveFilters) {
                    return;
                }
                e.preventDefault();
                this.pageNumber =
                    e.target.closest(".jsChangePage").target.dataset.pagenum;
                this.getProductsByAttr(Array.from(this.attrList));
            } else if (e.target.classList.contains("jsChangePrevNextPage")) {
                if (!this.isActiveFilters) {
                    return;
                }
                e.preventDefault();
                if (e.target.dataset.pagenum === "prev") {
                    this.pageNumber--;
                } else if (e.target.dataset.pagenum === "next") {
                    this.pageNumber++;
                }
                this.getProductsByAttr(Array.from(this.attrList));
            } else if (e.target.closest(".jsChangePrevNextPage")) {
                if (!this.isActiveFilters) {
                    return;
                }
                e.preventDefault();
                if (e.target.dataset.pagenum === "prev") {
                    this.pageNumber--;
                } else if (e.target.dataset.pagenum === "next") {
                    this.pageNumber++;
                }
                this.getProductsByAttr(Array.from(this.attrList));
            }
            else if (e.target.closest('.range-min') || e.target.closest('.range-max')) {
                this.getProductsByAttr(Array.from(this.attrList));
            }
        });

        this.orderBy?.addEventListener('change', (e) => {
            e.preventDefault();
            this.getProductsByAttr(Array.from(this.attrList));
        })
    }

    prepareSelectRequest(element) {
        const attrName = element.dataset.info;
        const attrKey = element.dataset.key;

        element.classList.remove("jsAttrFilter");

        element.classList.add("sidebar__attribute--active", "jsDeleteFilter");

        document
            .querySelector(".jsAccordionHeader")
            .closest(".sidebar__wrap")
            .classList.remove("open");

        if (!this.attrList.get(attrKey)) {
            this.attrList.set(attrKey, [attrName]);
        } else {
            if (!this.attrList.get(attrKey).includes(attrName)) {
                this.attrList.get(attrKey).push(attrName);
            }
        }

        if (!this.activeFilters.includes(attrName)) {
            this.activeFilters.push(attrName);
        }

        this.pageNumber = 1;

        this.getProductsByAttr(Array.from(this.attrList));
    }

    prepareDeleteRequest(element) {
        const deleteFilter = element.dataset.delete;
        const deleteKey = this.getKey(deleteFilter);
        const deleteElIndex = this.attrList
            .get(deleteKey)
            .indexOf(deleteFilter);
        const getActiveIndex = this.activeFilters.indexOf(deleteFilter);

        if (this.attrList.get(deleteKey).length > 1) {
            this.attrList.get(deleteKey).splice(deleteElIndex, 1);
        } else {
            this.attrList.delete(deleteKey);
        }
        this.activeFilters.splice(getActiveIndex, 1);

        element.classList.add("jsAttrFilter");

        element.classList.remove(
            "sidebar__attribute--active",
            "jsDeleteFilter"
        );

        document
            .querySelector(".jsAccordionHeader")
            .closest(".sidebar__wrap")
            .classList.remove("open");

        this.pageNumber = 1;

        this.getProductsByAttr(Array.from(this.attrList));
    }

    getKey(value) {
        return [...this.attrList].find(([key, val]) => val.includes(value))[0];
    }

    getProductsByAttr(attrList, loadMore = false) {
        this.isActiveFilters = true;
        const page = this.pageNumber;
        const currentLink = window.location.href;
        const url = new URL(currentLink);
        const search = url.searchParams.get("s") ?? "";
        new Loader(".jsArchiveContainer").enable();

        fetch(window.app_global.ajax_url + "?action=getProductByAttr", {
            method: "POST", //* GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", //* default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: new URLSearchParams({
                security: window.app_global.security,
                attr: JSON.stringify(attrList),
                active: Array.from(this.activeFilters),
                sortBy: this.orderBy.value,
                category: this.categoryTitle.dataset.catTitle,
                taxonomy: this.categoryTitle.dataset.taxonomy,
                page: page,
                search: search,
                priceMin: this.priceMin.value,
                priceMax: this.priceMax.value
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    this.archiveContainer.innerHTML = data.data;

                    new Loader(".jsArchiveContainer").disable();

                    if (search) {
                        history.pushState(
                            { page: this.pageNumber },
                            this.pageNumber,
                            "?s=" + search + "&page=" + this.pageNumber
                        );
                    } else {
                        const linkWithoutPage = currentLink.split("page");

                        history.pushState(
                            { page: this.pageNumber },
                            this.pageNumber,
                            linkWithoutPage[0].substring(
                                0,
                                linkWithoutPage[0].length - 1
                            ) +
                                "/page/" +
                                this.pageNumber
                        );
                    }

                    window.setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        });
                    }, 200);
                }
            });
    }
}

