export default class ProductListHover {
    constructor() {
        this.products = [
            ...document.querySelectorAll(".archive__products .product"),
        ];
        this.timeout = undefined;

        this.events();
    }

    events() {
        this.products.forEach((e) => {
            e.addEventListener("mouseenter", (element) => {
                this.addClass(element);

                if (
                    this.timeout &&
                    !element.target
                        .closest(".product")
                        .classList.contains("hover")
                ) {
                    clearTimeout(this.timeout);
                    this.timeout = undefined;
                }
            });
            e.addEventListener("mouseleave", (element) => {
                this.removeClass(element);
            });
        });
    }

    addClass(element) {
        if (!element.target.closest(".product").classList.contains("hover")) {
            element.target.style.height = "400px";
            element.target.closest(".product").classList.add("hover");
        }
    }

    removeClass(element) {
        this.timeout = setTimeout(() => {
            element.target.closest(".product").classList.remove("hover");
            element.target.style.height = "";
        }, 400);
    }
}
