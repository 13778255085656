
export default class Configurator {

    configuratorForm = document.querySelector('.jsConfiguratorForm')
    contactFormField = document.querySelector('#input_1_13');

    init() {
        this.events();
    }

    events() {
        this.configuratorForm.addEventListener('change', (e) => {
            this.recalculateConfiguratorPrice()
            this.showProductComment(e.target.closest('.jsConfiguratorOption'))
            this.updateContactForm()
        })
    }

    prepareFormData() {
        const productId = this.configuratorForm.dataset.productid;
        const allOptions = [...this.configuratorForm.querySelectorAll('.configurator__option-select')];
        const products = [...allOptions.map(option => option.value), productId];
        return products.filter(item => item !== "")
    }

    handleFormSubmit(event) {
        event.preventDefault();

        const qty = document.querySelector(".qty").value;

        const dataForm = this.prepareFormData(event.target, qty);

        fetch(window.app_global.ajax_url + "?action=addProductToCart", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: dataForm,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    const cartCount = document.querySelector(".header__cart-count");

                    cartCount.innerHTML = data.data.cart_count;

                    if (data.data.wc_notices != null && data.data.wc_notices !== "") {
                        this.noticesWrapper.innerHTML = data.data.wc_notices;

                        if (this.noticeContainer) {
                            this.noticeContainer.classList.remove("is-hidden");

                            window.setTimeout(() => {
                                this.noticeContainer.classList.add("is-hidden");
                            }, 10000);
                        }
                    }
                }
            });
    }

    recalculateConfiguratorPrice() {
        const dataForm = this.prepareFormData();

        fetch(window.app_global.ajax_url + "?action=calculateConfiguratorPrice", {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(dataForm),
        })
            .then((response) => response.json())
            .then((response) => {
                const { price, lowestPrice, deliveryDate } = response.data;
                document.querySelector(".jsConfiguratorPrice").innerHTML = price;
                document.querySelector(".jsLowestPrice").innerHTML = lowestPrice;
                document.querySelector(".jsDeliveryDate").innerHTML = deliveryDate;
            });
    }

    showProductComment(step) {
        const select = step.querySelector('.jsConfiguratorSelect');
        const comments = step.querySelectorAll('.jsConfiguratorComment');
        const selectedProductId = select.value;

        comments.forEach(comment => {
            const productId = comment.dataset.productid;

            if(selectedProductId == productId) {
                comment.classList.add('configurator__option-comment--active')
                return;
            }

            comment.classList.remove('configurator__option-comment--active')
        });
    }

    validate() {
        let isValid = true;
        const allOptions = [...this.configuratorForm.querySelectorAll('.configurator__option-select')];
        allOptions.forEach(option => {

            if(option.value == '') {
                option.classList.add('invalid')
                isValid = false;
            }
            else {
                option.classList.remove('invalid')
            }
        })

        return isValid;
    }

    updateContactForm() {

        const productTitle = document.querySelector('.single-product__title--main').innerText;
        const allOptions = [...this.configuratorForm.querySelectorAll('.configurator__option-select')];

        const configuration = allOptions.map(option => {
            const step = option.parentNode;
            const label = step.querySelector('.configurator__option-label').innerText;
            return ` ${label}: ${option.selectedOptions[0].text == 'Wybierz' ? 'Niewybrane' : option.selectedOptions[0].text}`;
        })

        this.contactFormField.value = `${productTitle}\r\n\r\nKonfiguracja:\r\n${configuration.join("\r\n")}`;
    }
}