export default class ContactFormModal {
    constructor() {
      this.closeModalBtn = document.querySelectorAll(".eip__close");
      this.modalOverlay = document.querySelectorAll(".jsContactFormModal");
      this.body = document.body;
  
      this.events();
    }
  
    events() {
        document.querySelector(".eip")?.addEventListener("click", this.tryExit.bind(this));
        document.querySelector(".eip__close")?.addEventListener("click", this.exit.bind(this));
        document.querySelector('.jsContactFormModalOpenBtn')?.addEventListener("click", this.displayModal.bind(this));
    }

    displayModal() {
      this.body.classList.add("disable-scroll");
      document.querySelector(".eip").classList.add("visible");
    }
  
    tryExit(e) {
      const shouldExit =
        [...e.target.classList].includes("eip") || // user clicks on mask
        e.target.className === "eip__close" || // user clicks on the close div
        e.keyCode === 27; // user hits escape
  
      if (shouldExit) {
        document.querySelector(".eip").classList.remove("visible");
        this.body.classList.remove("disable-scroll");
      }
    }
  
    exit(e) {
      document.querySelector(".eip").classList.remove("visible");
      this.body.classList.remove("disable-scroll");
    }
  }
  