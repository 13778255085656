export default class Loader {
    constructor(element) {
        if (element) {
            this.element = document.querySelector(element);
        }

        this.loaderHtml = document.createElement("div");
        this.loaderHtml.classList.add("loader");
    }

    enable() {
        if (this.element) {
            this.loaderHtml.style.position = "absolute";
            this.element.appendChild(this.loaderHtml);
        }
    }

    disable() {
        const elementLoader = document.querySelectorAll(".loader");

        if (elementLoader) {
            elementLoader.forEach((element) => {
                if (!element.classList.contains("is-unloading")) {
                    element.classList.add("is-unloading");

                    setTimeout(() => {
                        element.classList.remove("is-unloading");
                        element.parentElement.removeChild(element);
                    }, 250);
                }
            });
        }
    }
}
