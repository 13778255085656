export default class Accordion {
    constructor(className) {
        this.className = className;
        this.events();
    }

    events() {
        document.body.addEventListener("click", (e) => {
            const clickedElement = e.target.closest(`.${this.className}`);

            if (
                clickedElement &&
                clickedElement.classList.contains(this.className)
            ) {
                this.toggleAccordion(clickedElement.parentNode);
            }
        });
    }

    toggleAccordion(element) {

        if (element.classList.contains("open")) {
            element.classList.remove("open");
        } else {
            element.classList.add("open");
        }
    }
}
