export default class ExitIntentPopup {
  constructor() {
    this.closeModalBtn = document.querySelectorAll(".eip__close");
    this.modalOverlay = document.querySelectorAll(".eip");
    this.body = document.body;
    this.mouseEvent = this.mouseEvent.bind(this);

    this.events();
  }

  setCookie(name, value, days) {
    let expires = "";

    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "") + expires + ";path=/";
  }

  getCookie(name) {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      if (cookie.indexOf(name + "=") > -1) {
        return cookie.split("=")[1];
      }
    }

    return null;
  }

  events() {
    if (!this.getCookie("exitIntentShown")) {
      setTimeout(() => {
        document.addEventListener("mouseout", this.mouseEvent);
        document.addEventListener("keydown", this.tryExit.bind(this));
        document.querySelector(".eip").addEventListener("click", this.tryExit.bind(this));
        document.querySelector(".eip__close").addEventListener("click", this.exit.bind(this));
      }, 0);
    }
  }

  mouseEvent(e) {
    const shouldShowExitIntent = !e.toElement && !e.relatedTarget && e.clientY < 10;

    if (shouldShowExitIntent) {
      document.removeEventListener("mouseout", this.mouseEvent);
      this.displayModal();
      this.setCookie("exitIntentShown", true, 7);
    }
  }

  displayModal() {
    this.body.classList.add("disable-scroll");
    document.querySelector(".eip").classList.add("visible");
  }

  tryExit(e) {
    const shouldExit =
      [...e.target.classList].includes("eip") || // user clicks on mask
      e.target.className === "eip__close" || // user clicks on the close div
      e.keyCode === 27; // user hits escape

    if (shouldExit) {
      document.querySelector(".eip").classList.remove("visible");
      this.body.classList.remove("disable-scroll");
    }
  }

  exit(e) {
    document.querySelector(".eip").classList.remove("visible");
    this.body.classList.remove("disable-scroll");
  }
}
