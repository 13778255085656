import Splide from "@splidejs/splide";

export default class UpsellSlider {
    constructor() {
        this.everyProductSlider = [];
        this.productsSlider = document.querySelectorAll(".miniupsell__slider");
        this.events();
    }

    events() {
        window.addEventListener("DOMContentLoaded", () => {
            if (this.productsSlider.length) {
                this.initializeProductsSlider();
            }
        });
    }

    initializeProductsSlider() {
        this.productsSlider.forEach((item) => {
            this.everyProductSlider.push(
                new Splide(item, {
                    pagination: false,
                    perPage: 3,
                    perMove: 1,
                    gap: 16,
                    arrows: false,
                    trimSpace: true,
                    breakpoints: {
                        
                        575: {
                            perPage: 1.3,
                        },
                    },
                }).mount()
            );
        });
    }
}