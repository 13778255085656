export default class MobileMenu {
    constructor() {
        this.topbar = document.querySelector('.topbar')
        this.toggleMenuBtn = document.querySelector('.jsMobileMenuToggle');
        this.mobileMenu = document.querySelector('.jsMobileMenu');
        this.isOpen = false;

        this.toggleMenuBtn.addEventListener('click', (event) => {
            event.preventDefault();
            this.isOpen = !this.isOpen;
            
            this.toggleMenuBtn.classList.toggle('active')
            this.mobileMenu.classList.toggle('mobile-menu--active');
            document.body.classList.toggle('scroll-lock')
            
            if(this.isOpen) {
                this.topbar.style.padding = "5px 0";
                this.topbar.style.maxHeight = "30px";
            }else {
                this.topbar.style.padding = null;
                this.topbar.style.maxHeight = null;
            }
        })
    }
}
